
export const DateResolver = ( item ) => {
      
      const splitedDate = item.split('-');

      let value = `${splitedDate[2]} de ${MonthResolver(splitedDate[1])} del ${splitedDate[0]}`
     
      return value
}

export default DateResolver;


const MonthResolver = ( month ) => {

      let value = "";

      if(month == '01'){
            value = "Enero"
      }else if(month == '02'){
            value ="Febrero"
      }else if(month == '03'){
            value ="Marzo"
      }else if(month == '04'){
            value ="Abril"
      }else if(month == '05'){
            value ="Mayo"
      }else if(month == '06'){
            value ="Junio"
      }else if(month == '07'){
            value ="Julio"
      }else if(month == '08'){
            value ="Agosto"
      }else if(month == '09'){
            value ="Setiembre"
      }else if(month == '10'){
            value ="Octubre"
      }else if(month == '11'){
            value ="Noviembre"
      }else if(month == '12'){
            value ="Diciembre"
      }
      return value
}

